<template>
  <div class='py-2 lg:py-8'
       style='min-height: calc(100vh - 16rem);'>
    <div class='layout-container' v-if='hasSessions'>
      <program-schedule class='mt-16 px-2 lg:px-0'/>
    </div>
    <div class='mt-32' v-else>
      <div class='text-3xl text-center'>There are no more sessions for today</div>
    </div>
  </div>
</template>

<script>
import ProgramSchedule          from '@/components/programs/ProgramSchedule.vue'
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'Live',
  components: {
    ProgramSchedule
  },
  computed: {
    ...mapGetters('sessions', [
      'hasSessions'
    ])
  },
  methods: {
    ...mapActions('sessions', [
      'getSessions',
    ]),
  },
  mounted () {
    this.getSessions('remaining')
  }
}
</script>

